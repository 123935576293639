import { createContext, useEffect, useState } from "react";
import { ethers } from 'ethers';

import chibiCats from "../abis/chibiCats.json";
import chibiDust from "../abis/chibiDust.json"
import chibiStaking from "../abis/chibiStaking.json";
import { Provider as MulticallProvider, Contract as MulticallContract } from "ethers-multicall";
import { BigNumber, utils } from "../../node_modules/ethers/lib/ethers";
import { toast } from 'react-toastify';
import axios from "axios"

const Web3Context = createContext();

const RPC_URL = "https://api.s0.b.hmny.io";
const CHAIN_ID = 1666700000;
const NATIVE_CURRENCY = {
    name: "one",
    symbol: "ONE", // 2-6 characters long
    decimals: 18,
}
const MULTI_CALL_ADDRESS = "0xd078799c53396616844e2fa97f0dd2b4c145a685";
const CHAIN_NAME = "Harmony Testnet";
const BASE_URL = "http://localhost:5000/proposal";


export const CHIBI_CONTRACT_ADDRESS = "0x3E3b91C55e9675759182B9CC2dB14498cE5Ff3A8";
export const STAKING_CONTRACT_ADDRESS =
    "0xbd4957D412548d2C554502FFc747cedFBe0F8dAD";
export const DUST_CONTRACT_ADDRESS = "0x3845F6BA1a1Ec282E20B99d08f4FFF08A7Ece6F7";

export const Web3Provider = (props) => {

    const [account, setAccount] = useState();
    const [signer, setSigner] = useState();
    const [contractObjects, setContractObjects] = useState();
    const [baseCoinPrice, setBaseCoinPrice] = useState()
    const [isPaused, setIsPaused] = useState()
    const [update, setUpdate] = useState(0);
    const [stats, setStats] = useState({})
    const functionsToExport = {};

    const onAccountsChanged = async (accounts) => {
        setAccount(accounts[0]);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const _signer = provider.getSigner();
        setSigner(_signer);
    }

    useEffect(() => {
        try {
            const _signer = signer || new ethers.providers.Web3Provider(
                window.ethereum,
                "any"
            );
            const chibiContract = new ethers.Contract(CHIBI_CONTRACT_ADDRESS, chibiCats, _signer);
            const stakingContract = new ethers.Contract(STAKING_CONTRACT_ADDRESS, chibiStaking, _signer);
            const dustContract = new ethers.Contract(DUST_CONTRACT_ADDRESS, chibiDust, _signer);

            const _contractObjects = {
                chibiContract,
                stakingContract,
                dustContract
            }
            setContractObjects(_contractObjects);
        }
        catch (e) {
            console.log(e)
        }
    }, [signer])
    const addNewChain = async () => {
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
                {
                    chainId: `0x${CHAIN_ID.toString(16)}`,
                    rpcUrls: [RPC_URL],
                    chainName: CHAIN_NAME,
                    nativeCurrency: NATIVE_CURRENCY,
                },
            ],
        });
    }
    const switchCain = async () => {
        await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${CHAIN_ID.toString(16)}` }],
        });
    }
    const promptChain = async () => {
        try {
            await switchCain();
        }
        catch (e) {
            await addNewChain();
            // await switchCain();
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const _signer = provider.getSigner();
        setSigner(_signer);

    }
    const onChainChanged = async (chainID) => {

        await promptChain()
    }
    const setupMultiCallContract = async (contractAddress, abi) => {
        const provider = new ethers.providers.Web3Provider(
            window.ethereum,
            "any"
        );
        const ethcallProvider = new MulticallProvider(provider);

        await ethcallProvider.init();
        ethcallProvider._multicallAddress =
            MULTI_CALL_ADDRESS;
        console.log(contractAddress, abi);
        console.log(provider)
        console.log(ethcallProvider);

        const multicallContract = new MulticallContract(contractAddress, abi);
        return ([ethcallProvider, multicallContract]);

    }
    functionsToExport.connectWallet = async (defaultAccount = -1) => {
        const { ethereum } = window

        if (!ethereum) {
            toast.error("You need a wallet to continue!");
            return
        }

        if (ethereum) {
            await ethereum.request({ method: 'eth_requestAccounts' });
            const accounts = await ethereum.request({ method: 'eth_accounts' });
            await promptChain()
            ethereum.on('chainChanged', onChainChanged);
            ethereum.on('accountsChanged', onAccountsChanged);
            setAccount(accounts[0]);
            toast.success("Wallet Connected!")
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const _signer = provider.getSigner();
            setSigner(_signer);
        }
    }


    functionsToExport.stake = async (tokenIds) => {
        try {
            const isPlotsApproved = await contractObjects?.chibiContract?.isApprovedForAll(account, STAKING_CONTRACT_ADDRESS);
            if (!isPlotsApproved) {
                toast(`Approving Staking Contract`)

                const approveIt = await contractObjects?.chibiContract?.setApprovalForAll(STAKING_CONTRACT_ADDRESS, true);
                const newBattleId = await approveIt.wait();
                toast(`Staking Contract Approved!`)

            }

            toast.success(`Placing Transaction`);

            const n = await contractObjects.stakingContract.stake(tokenIds)
            await n.wait()
            toast.success("Transaction Successful");
            return n
        }
        catch (e) {
            console.log(e)
            toast.error(e?.message)
            return false
        }
    }
    functionsToExport.unstake = async (tokenIds) => {
        try {
            toast(`Placing Transaction`);

            const n = await contractObjects.stakingContract.unStake(tokenIds)
            toast(`Transaction Placed`);

            await n.wait()
            toast.success("Transaction Successful!")

            return n
        }
        catch (e) {
            console.log(e)
            toast.error(e?.message)
            return false
        }
    }
    functionsToExport.claimRewards = async (tokenIds) => {
        try {
            toast(`Placing Transaction`);

            const n = await contractObjects.stakingContract.claimRewards(tokenIds)
            toast(`Transaction Placed`);

            await n.wait()
            toast.success("Transaction Successful!")
            return n
        }
        catch (e) {
            console.log(e)
            toast.error(e?.message)
            return false
        }
    }
    functionsToExport.claimReflections = async (tokenIds) => {
        try {
            toast(`Placing Transaction`);

            const n = await contractObjects.stakingContract.claimReflections(tokenIds)
            toast(`Transaction Placed`);

            await n.wait()
            toast.success("Transaction Successful!")

            return n
        }
        catch (e) {
            console.log(e)
            toast.error(e?.message)
            return false
        }
    }

    functionsToExport.getUserStaked = async () => {
        try {
            const n = await contractObjects.stakingContract.getUserStaked(account)
            return n
        }
        catch (e) {
            console.log(e)
            toast.error(e?.message)
            return false
        }
    }
    functionsToExport.getUserUnStaked = async () => {
        try {
            const userBalance = parseInt((await contractObjects?.chibiContract?.balanceOf(account)).toString());
            console.log("ChibiCats", userBalance);
            console.log(CHIBI_CONTRACT_ADDRESS, chibiCats)
            chibiCats.map(e => console.log(e));
            const [multicallProvider, multicallContract] = await setupMultiCallContract(CHIBI_CONTRACT_ADDRESS, chibiCats);
            let tokenCalls = []
            for (let i = 0; i < userBalance; i++) {
                tokenCalls.push(multicallContract.tokenOfOwnerByIndex(account, i));
            }
            const userTokens = (await multicallProvider?.all(tokenCalls)).map(e => e.toString());
            return userTokens;
        }
        catch (e) {
            console.log(e)
            toast.error(e?.message)
            return false
        }
    }
    functionsToExport.getUserUnStakedCount = async () => {
      try {
        const n = parseInt(
          (await contractObjects?.chibiContract?.balanceOf(account)).toString()
        );
        return n;
      } catch (e) {
        console.log(e);
        toast.error(e?.message);
        return false;
      }
    };
    functionsToExport.getUserStakedCount = async () => {
      try {
        const n = await contractObjects.stakingContract.getUserStaked(account);
        return n.length;
      } catch (e) {
        console.log(e);
        toast.error(e?.message);
        return false;
      }
    };
    functionsToExport.getRewards = async (tokenIds) => {
      try {
        const [multicallProvider, multicallContract] =
          await setupMultiCallContract(STAKING_CONTRACT_ADDRESS, chibiStaking);
        let tokenCalls = [];
        for (let i = 0; i < tokenIds.length; i++) {
          tokenCalls.push(multicallContract.getRewards(tokenIds[i]));
        }
        const rewards = (await multicallProvider?.all(tokenCalls)).map((e) =>
          e.toString()
        );
        return ethers.utils.formatEther(rewards[0]);
      } catch (e) {
        console.log(e);
        toast.error(e?.message);
        return false;
      }
    };

    functionsToExport.calculateReward = async (tokenIds) => {
      try {
        const [multicallProvider, multicallContract] =
          await setupMultiCallContract(CHIBI_CONTRACT_ADDRESS, chibiCats);
        let tokenCalls = [];
        for (let i = 0; i < tokenIds.length; i++) {
          tokenCalls.push(multicallContract.calculateReward(tokenIds[i]));
        }
        const rewards = (await multicallProvider?.all(tokenCalls)).map((e) =>
          e.toString()
        );
        let rewardTokens = [];
        for (let i = 0; i < tokenIds.length; i++) {
          if (rewards[i]?.toString() !== "0") {
            rewardTokens.push(Number(tokenIds[i].toString()));
          }
        }
        return [rewards, rewardTokens];
      } catch (e) {
        console.log(e);
        toast.error(e?.message);
        return false;
      }
    };



    return (<Web3Context.Provider value={{ account, isPaused, baseCoinPrice, stats, ...functionsToExport }}>
        {props.children}
    </Web3Context.Provider>)
}
export default Web3Context;