import React, { Component } from 'react';
import {Link} from "react-router-dom"
const initData = {
    pre_heading: "Auctions",
    heading: "Live Auctions",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    btnText: "Load More"
}

const data = [
    {
        id: "1",
        img: "/img/chibi_cats_l.png",
        date: "2021-12-09",
        title: "Chibi Cats Cafe",
        seller_thumb: "https://chibi-cat-cafe.herokuapp.com/image/2",
        seller: "@Richard",
        price: "1.5 BNB",
        count: "1 of 1"
    },
]

class AuctionsTwo extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="live-auctions-area load-more">
                <div className="container">
                        <h1 className="mt-4 mx-auto pt-4 flex text-center" style={{ "display": "flex", color: "#fff", justifyContent: "center" }}><div className="inline" style={{ color: "#ff66c4", "marginRight": "16px" }}>All </div>Projects</h1>

                    <div className="row justify-content-center items">
                        {this.state.data.map((item, idx) => {
                            return (
                                <div key={`auct_${idx}`} className="col-12 col-sm-6 col-lg-8">
                                    <div className="card">
                                        <div className="image-over">
                                            <Link to="/projects/chibi-cats">
                                                <img className="card-img-top" src={item.img} alt="" />
                                            </Link>
                                        </div>
                                        {/* Card Caption */}
                                        <div className="card-caption col-12 p-0">
                                            {/* Card Body */}
                                            <div className="card-body ">
                                             <div style={{display:'flex',flexDirection:"column",justifyItems:"center",alignItems:"center"}}>
                                                <Link to="/projects/chibi-cats">
                                                    <h5 className="mb-0" style={{fontSize:"28px"}}>Chibi Cats Cafe</h5>
                                                    </Link>
                <a href="https://www.chibicatscafe.one/">www.chibicatscafe.one</a>                           
                                                                                                    

                                                    </div>
                        <div className="card-bottom d-flex justify-content-between">
                        <span>Daily Reward</span>
                        <span>1 xDust per Token</span>
                        </div>
                        <div className="card-bottom d-flex justify-content-between">
                        <span>Chibi Cats Address </span>
                        <span style={{whiteSpace: "nowrap",
  overflow: "hidden"}}> 0xada4dbdd000b7cd3c4a116044bcb5d5c61d1b9d4</span>
                        </div>
                        <div style={{display:'flex',flexDirection:"column",justifyItems:"center",alignItems:"center"}}>
                            <Link to="/projects/chibi-cats" className="btn btn-bordered-white btn-smaller mt-3" >View NFTS</Link>
                        </div>                      
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    
                </div>
            </section>
        );
    }
}

export default AuctionsTwo;