import {useContext,useEffect} from "react";
import { useMetaMask } from "metamask-react";
import Header from "../components/Header/Header";
import Explore from "../components/Projects/Explore";
import Footer from "../components/Footer/Footer";
import ModalSearch from "../components/Modal/ModalSearch";
import ModalMenu from "../components/Modal/ModalMenu";
import Scrollup from "../components/Scrollup/Scrollup";
import Web3Context from '../contexts/Web3Context'
export default function ProjectsDetails() {
  const { connectWallet, account } = useContext(Web3Context);
        return (
            <div className="main">
                <Header />
                <section className="text-center pb-0" >
                                <h2 className="mt-4">Chibi Cats Cafe</h2>
                </section>
                {account?<Explore />:<div className="text-center"><button className="btn btn-bordered-white btn-smaller mt-3" onClick={()=>connectWallet()}>Connect Wallet</button></div>}
                {/* <ModalSearch /> */}
                {/* <ModalMenu /> */}
                {/* <Scrollup /> */}
            </div>
        );
    }

