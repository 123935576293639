import {useState,useEffect,useContext} from 'react';
import ExploreCard from "./ExploreCard"
import Web3Context from '../../contexts/Web3Context'
export default function Explore() {
    const [stakedcount,setStakedCount]=useState(false)
    const [unstakedcount,setUnstakedCount]=useState(false)
  const {account,getUserStaked,getUserUnStaked,getUserUnStakedCount,getUserStakedCount,claimRewards,claimReflections,unstake,stake,calculateReward } = useContext(Web3Context);
    const [stakeddata, setstakeddata] = useState(false)
    const [update, setUpdate] = useState(0);
  const [unstaked,setunstaked]=useState(false)
    async function getdata(){
        let t=await getUserStaked()
        setstakeddata(t)
        let k=await getUserUnStaked()
        setunstaked(k)
    }
    async function claimRewardsclicked(e){
        e.preventDefault();
        await claimRewards(stakeddata)
        setUpdate(val => val + 1);
    }
    async function unstakeclicked(e){
        e.preventDefault();
        await unstake(stakeddata)
        setUpdate(val => val + 1);
    }
    async function stakeclicked(e){
        e.preventDefault();
        await stake(unstaked)
        setUpdate(val => val + 1);
    }
    async function claimReflectionsclicked(e){
        e.preventDefault();
        const tokens=await calculateReward(stakeddata)
        console.log("tokens",tokens)
        await claimReflections(tokens[1])
        setUpdate(val => val + 1);
    }
    useEffect(async()=>{
        setStakedCount(await getUserStakedCount())
        setUnstakedCount(await getUserUnStakedCount())
    },[account])
    useEffect(()=>{
        getdata()
    },[update])
    const [staked,setStaked]=useState(true)
        return (
            <section className="explore-area pt-0">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-12">
                            <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                                    <button className="btn btn-bordered-white btn-smaller mt-3" onClick={(e)=>{e.preventDefault();setStaked(true)}}>Staked</button>
                                    <button className="btn btn-bordered-white btn-smaller mt-3" onClick={(e)=>{e.preventDefault();setStaked(false)}}>Unstaked</button>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center text-center">
                        <div className="col-12">
                    <div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                                  {staked?stakedcount?<>You have {stakedcount} staked {stakedcount==1?"NFT":"NFTS"}</>:"You don't have any staked NFTS":unstakedcount?<>You have {unstakedcount} unstaked {unstakedcount==1?"NFT":"NFTS"}</>:"You don't have any unstaked NFTS"}
                            </div>
                        </div>
                    </div>
                    {staked?<div className="row justify-content-center text-center">
                        <div className="col-12">
                            {stakeddata&&stakeddata.length!=0&&<div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                                    <button className="btn btn-bordered-white btn-smaller mt-3" onClick={unstakeclicked}>Unstake all</button>
                                    <button className="btn btn-bordered-white btn-smaller mt-3" onClick={claimRewardsclicked}>Claim Reward All</button>
                                    <button className="btn btn-bordered-white btn-smaller mt-3" onClick={claimReflectionsclicked}>Reflect All</button>
                            </div>}
                        </div>
                        </div>:<div className="row justify-content-center text-center">
                        <div className="col-12">
                            {unstaked&&unstaked.length!=0&&<div className="explore-menu btn-group flex-wrap justify-content-center text-center mb-4">
                                <button className="btn btn-bordered-white btn-smaller mt-3" onClick={stakeclicked}>Stake All</button>
                            </div>
                            }
                        </div>
                        </div>}

                    <div className="row items justify-content-center explore-items">
                        {staked&&stakeddata&&(stakeddata.length?stakeddata.map((item) => <ExploreCard key={item} token={item} staked={staked} />):<>No Staked NFTS</>)}
                        {!staked&&unstaked&&(unstaked.length?unstaked.map((item) => <ExploreCard key={item} staked={staked} token={item} />):<>No Unstaked NFTS</>)}
                    </div>
                </div>
            </section>
        );
    }
