import React from 'react'
import Typewriter from 'typewriter-effect';
import Header from '../components/Header/Header';

export default function Home() {
  return (
    <>
      <Header/>
      <section className="hero-section">
        
                <div style={{ "marginTop": "100px" }} className="container hero-container">

                    <div style={{ "display": "flex" }} className="d-flex my-auto text-center flex-column justify-center align-items-center">
                        {/* <span>{this.state.data.pre_heading}</span> */}
                        <h1 className="mt-4 pt-4 flex" style={{ "display": "flex" }}><div className="inline" style={{ color: "#ff66c4", "marginRight": "16px" }}>EZ </div>Launch</h1>
                        <p>Token Launch made Easier!</p>
                        {/* Buttons */}
                        <div className="button-group">
                            <a
                                className="btn btn-bordered-white m-2"
                                target={""}
                                href="#"
                            >
                                Explore
                            </a>
                        </div>
                    </div>
                </div>
                {/* How IT WORKS */}
                <div className="container text-center py-5 my-5">
    <div className="row justify-content-center mb-4 mt-0">
      <div className="col-md-7 ">
        <h3 className="mb-3">How It Works</h3>
        {/* <h6 className="subtitle font-weight-normal">You can relay on our amazing features list and also our customer services will be great experience for you without doubt and in no-time</h6> */}
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 mb-4">
        <div className="row no-gutters">
          <div className="col-md-12">
            <div className="pt-2">
              <h5 className="mt-4 font-weight-medium mb-0">Step 1</h5>
              <p className="mt-3">Apply to setup an ICO</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 mb-4">
        <div className="row no-gutters">
          <div className="col-md-12">
            <div className="pt-2">
              <h5 className="mt-4 font-weight-medium mb-0">Step 2</h5>
              <p className="mt-3">Team verifies and whitelists your address for ICO</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 mb-4">
        <div className="row no-gutters">
          <div className="col-md-12">
            <div className="pt-2">
              <h5 className="mt-4 font-weight-medium mb-0">Step 3</h5>
              <p className="mt-3">Fill the form to deploy ICO contract and launch ICO</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 mb-4">
        <div className="row no-gutters">
          <div className="col-md-12">
            <div className="pt-2">
              <h5 className="mt-4 font-weight-medium mb-0">Step 4</h5>
              {/* <h6 className="subtitle font-weight-normal">Property Specialist</h6> */}
              <p className="mt-3">Transfer tokens to ICO to deployed contract</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
              {/* PARTNERSHIP */}
                <div className="w-full pt-5">
                    <h1 className="mt-4 pt-4 w-full text-center" style={{ "textAlign": "center" }}><div className="inline" style={{ color: "#fff" }}>EZ Launch</div><div>X</div>
                        <img className="mx-auto" style={{ height: "250px", "marginLeft": "auto", "marginRight": "auto" }} src={"/img/chibi_cats.png"} />
                    </h1>
                    <div style={{ "color": "#fff", "fontSize": "22px", "textAlign": "center", "maxWidth": "800px", "marginLeft": "auto", "marginRight": "auto" }}>
                        <b style={{ "fontSize": "24px" }}>Founding Partnership with Chibi Cats</b><br /><br />
                        EzTools has partnered with Chibi Cats to bring easy solution for NFT staking for the community. Under this initiative we will reward Chibi Cat holders as this project grows and becomes more adopted.
                    </div>
                </div>
            </section>
  
    </>
  )
}
