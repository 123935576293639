import {useEffect,useState} from 'react'
import {toast} from "react-toastify"
import Select from 'react-select'
const options = [
    { value: 'ONE',img:"https://openfi.dev/tokens/default/ONE.png", label: <div className="text-center"><img src={"https://openfi.dev/tokens/default/ONE.png"} height="30px" width="30px"/>ONE</div> },
    { value: 'ETH',img:"https://openfi.dev/tokens/default/ETH.png", label: <div className="text-center"><img src={"https://openfi.dev/tokens/default/ETH.png"} height="30px" width="30px"/>ETH</div> },
  ];
export default function Ico() {
    const [from,setFrom]=useState(false)
    const [to,setTo]=useState(false)
    const [state,setState]=useState(true)
    async function onNext(e){
        e.preventDefault();
        console.log(from,to)
        if(from.value&&to.value){
            if(from.value!=to.value){
                setState(!state)
                console.log(from,to)
            }
            else{
            toast.error("Both are same")
            }
        }else{
           toast.error("Please Select both tokens") 
        }
    }
  return (
    <div className="main mt-5 pt-5">
        <div className="container text-center">
  <div className="row justify-content-center" >
    <div className="col-12 col-sm-6 col-lg-6">
                                    {state?<div className="card p-5" style={{backgroundColor:"##ce40406d"}}>
                                        <div className="card-caption col-12 p-0">
                                            {/* Card Body */}
                                            <div className="card-body ">
                                                    <Select placeholder={"Select Token"} onChange={(e)=>setFrom({value:e.value,img:e.img})} options={options} />
                                                    <Select placeholder={"Select Token"} onChange={(e)=>setTo({value:e.value,img:e.img})} className='mt-2' options={options} />
                                            </div>
                                            <button onClick={onNext} style={{ position: 'relative', zIndex: '0' }} className="btn m-2 py-2 px-5">Next</button>
                                        </div>
                                    </div>:
                                    <div className="card p-5" style={{backgroundColor:"##ce40406d"}}>
                                        <div className="card-caption col-12 p-0">
                                            {/* Card Body */}
                                            <div className="card-body ">
        <div className="input-group mb-3">
          <input type="number" style={{backgroundColor:"transparent",borderColor:"#544bdb",color:"#fff"}} className="form-control p-2" />
          <div className="input-group-append">
            <img src={from.img} width="40px"/>
          </div>
        </div>
        <div className="input-group mb-3">
          <input type="number" style={{backgroundColor:"transparent",borderColor:"#544bdb",color:"#fff"}} className="form-control p-2" />
          <div className="input-group-append">
            <img src={to.img}  width="40px"/>
          </div>
        </div>
                                            </div>
                                            <button onClick={onNext} style={{ position: 'relative', zIndex: '0' }} className="btn m-2 py-2 px-5">Back</button>
                                            <button onClick={()=>{toast.success("Swapped")}} style={{ position: 'relative', zIndex: '0' }} className="btn m-2 py-2 px-5">Swap</button>
                                        </div>
                                    </div>}
                                </div>
  </div>
</div>
    </div>
  )
}
