import {get} from "axios"
import {useEffect,useState,useContext} from "react"
import Web3Context from '../../contexts/Web3Context'
import {ethers} from "ethers"
export default function ExploreCard({token,staked}) {
    const {claimRewards,claimReflections,stake,unstake,getRewards,calculateReward } = useContext(Web3Context);
    async function stakeclick(e){
        e.preventDefault()
        await stake([token])
    }
    async function unstakeclick(e){
        e.preventDefault()
        await unstake([token])
    }
    async function claimReflectionsclick(e){
        e.preventDefault()
        await claimReflections([token])
    }
    async function claimRewardsclick(e){
        e.preventDefault()
        await claimRewards([token])
    }

    const [name,setName]=useState(false)
    const [reward,setReward]=useState(false)
    const [reflectionreward,setReflection]=useState(false)
    async function getdetails(){
        const {data}=await get(`https://chibi-cat-cafe.herokuapp.com/${token}`)
        setName(data.name)
        if(staked){
        const reward=await getRewards([token])
        setReward(reward)
        const reflection=await calculateReward([token])
        setReflection(ethers.utils.formatEther(reflection[0][0]))
        }
    }
    useEffect(()=>{
        getdetails()
    },[])
  return (
    <div  className="col-12 col-sm-6 col-lg-4 item explore-item">
        <div className="card">
            <div className="image-over">
                    <img className="card-img-top" src={`https://chibi-cat-cafe.herokuapp.com/image/${token}`} alt="" />
            </div>
            {/* Card Caption */}
            <div className="card-caption col-12 p-0">
                {/* Card Body */}
                <div className="card-body">
                        <h5 className="mb-2">{name}</h5>
                    {/* <div className="seller d-flex align-items-center my-3">
                        <span>Owned By</span>
                        <a href="/author">
                            <h6 className="ml-2 mb-0">{owner}</h6>
                        </a>
                    </div> */}
                    {staked&&<><div className="card-bottom d-flex justify-content-between">
                        <span>Staking reward</span>
                        <span>{parseFloat(reward).toFixed(5)} xDust</span>
                    </div>
                    <div className="card-bottom d-flex justify-content-between">
                        <span>Reflection Reward</span>
                        <span>{parseFloat(reflectionreward).toFixed(5)} ONE</span>
                    </div>
                    </>}
                    {staked&&<div className="card-bottom d-flex justify-content-between">
                    <button className="btn btn-bordered-white btn-smaller mt-3" onClick={claimRewardsclick} >Claim reward</button>
                    <button className="btn btn-bordered-white btn-smaller mt-3 ml-2" onClick={claimReflectionsclick}>Claim Reflection</button>
                    </div>}
                    <div className='text-center'>
                    {staked?<button className="btn btn-bordered-white btn-smaller mt-3" onClick={unstakeclick}>Unstake</button>:<button className="btn btn-bordered-white btn-smaller mt-3"  onClick={stakeclick}>Stake</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
